<template>
  <div :class="{ 'nav-open': sidebar.showSidebar }">
    <template v-for="position in positions" :key="position">
      <notifications
        :group="position"
        transition-name="notification-list"
        transition-mode="out-in"
        :position="position"
      >
        <!--<template #body="{ item }">
          <component :is="item.data.template" :item="item" />
        </template>-->
      </notifications>
    </template>
    <router-view name="header"></router-view>
    <router-view v-slot="{ Component }">
      <transition name="fade" mode="out-in">
        <component :is="Component" />
      </transition>
    </router-view>
    <router-view name="footer"></router-view>
  </div>
</template>

<script>
// Loading some plugin css asynchronously
import "sweetalert2/dist/sweetalert2.css";
import "element-plus/dist/index.css";
//impor "vue-notifyjs/themes/default.css";
import { inject } from "vue";

export default {
  setup() {
    const sidebar = inject("sidebar");
    const positions = [
      "top left",
      "top center",
      "top right",
      "bottom left",
      "bottom center",
      "bottom right",
    ];
    return {
      positions,
      sidebar,
    };
  },
};
</script>
<style lang="scss"></style>
