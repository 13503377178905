<template>
  <span :class="`rounded-avatar-${size}`" :style="{ backgroundColor }">{{
    initials
  }}</span>
</template>

<script>
import { computed } from "vue";

export default {
  props: {
    text: {
      type: String,
      default: "default",
    },
    size: {
      type: String,
      default: "large",
      validator: function (value) {
        // The value must match one of these strings
        return ["large", "small", null].indexOf(value) !== -1;
      },
    },
  },
  setup(props) {
    function stringToHslColor(str, s, l) {
      var hash = 0;
      for (var i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
      }

      var h = hash % 360;
      return "hsl(" + h + ", " + s + "%, " + l + "%)";
    }

    const color = computed(() => stringToHslColor(props.text, 30, 80));

    const initials = computed(
      () =>
        props.text.charAt(0) + props.text.charAt(props.text.indexOf(" ") + 1)
    );

    return {
      backgroundColor: color,
      initials,
    };
  },
};
</script>

<style scoped>
.rounded-avatar-large {
  display: inline-grid;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  align-content: center;
  font-size: xx-large;
  text-transform: uppercase;
  color: white;
  text-align: center;
}

.rounded-avatar-small {
  display: inline-grid;
  width: 35px;
  height: 35px;
  border-radius: 25%;
  align-content: center;
  font-size: small;
  text-transform: uppercase;
  color: white;
  text-align: center;
}
</style>
