<template>
  <el-button-group size="small" class="ml-4 mb-3">
    <el-button
      v-for="item in items"
      :key="item"
      :active="item.toggled"
      color="transparent"
      :class="[item.toggled ? toggledClass : '']"
      @click="OnClick(item.type)"
      >{{ item.text }}</el-button
    >
  </el-button-group>
</template>

<script>
import { ref, reactive, watch } from "vue";
import Moment from "moment";

const filters = {
  MONTH: 1,
  LASTQ: 2,
  YEAR: 3,
  YTD: 4,
  ALL: 5,
};

export default {
  props: {
    type: {
      type: String,
      default: "all",
      description: "The state of the toggle",
      validator(value) {
        // The value must match one of these strings
        return ["all", "ytd", "year", "lastq", "month"].includes(value);
      },
    },
  },
  emits: ["toggled", "update:type"],
  setup(props, { emit }) {
    const OnClick = (filter) => {
      items.forEach((el) => {
        el.toggled = false;
        if (el.type == filter) {
          emit("update:type", el.name);
          el.toggled = true;
        }
      });
      var date = new Date();
      var toDate = new Date();
      switch (filter) {
        case filters.MONTH: {
          date.setMonth(date.getMonth() - 1);
          toDate = null;
          break;
        }
        case filters.LASTQ: {
          const q = Math.floor(toDate.getMonth() / 3);
          date.setMonth(0 + 3 * q);
          date.setDate(1);
          toDate = null;
          break;
        }
        case filters.YEAR: {
          date.setFullYear(date.getFullYear() - 1);
          toDate = null;
          break;
        }
        case filters.YTD: {
          date.setMonth(0);
          date.setDate(1);
          break;
        }
        case filters.ALL: {
          date = null;
          toDate = null;
          break;
        }
      }

      emit("toggled", {
        from: Moment(date).format("YYYY-MM-DD"),
        to: toDate != null ? Moment(toDate).format("YYYY-MM-DD") : null,
      });
    };
    const toggledClass = ref("toggled");
    const items = reactive([
      {
        text: "Month",
        name: "month",
        toggled: ref(false),
        type: filters.MONTH,
      },
      {
        text: "Last Q",
        name: "lastq",
        toggled: ref(false),
        type: filters.LASTQ,
      },
      { text: "Year", name: "year", toggled: ref(false), type: filters.YEAR },
      { text: "YTD", name: "ytd", toggled: ref(false), type: filters.YTD },
      { text: "All", name: "all", toggled: ref(false), type: filters.ALL },
    ]);

    items.forEach((el) => {
      el.toggled = false;
      if (el.name == props.type) {
        el.toggled = true;
      }
    });

    watch(
      () => props.type,
      () => {
        items.forEach((el) => {
          el.toggled = false;
          if (el.name == props.type) {
            el.toggled = true;
          }
        });
      }
    );

    return {
      toggledClass,
      items,
      OnClick,
    };
  },
};
</script>

<style scoped>
.el-button-group > .el-button:active,
.el-button-group > .el-button:focus,
.el-button-group > .el-button:hover {
  color: white;
}

.toggled {
  border-color: var(--el-button-active-border-color);
  background-color: var(--el-button-active-bg-color);
  outline: 0;
  color: white;
}
</style>
