<template>
  <div
    class="form-group"
    :class="[
      { 'input-group': hasIcon },
      { 'has-danger': !!errorMessage },
      { 'input-group-focus': focused && !errorMessage },
      { 'has-label': label || $slots.label },
      { 'has-success': hasSuccess },
    ]"
  >
    <slot name="label">
      <label v-if="label" :class="labelClasses">
        {{ label }}
        <span v-if="required" class="text-danger">*</span>
      </label>
    </slot>

    <div
      v-if="addonLeftIcon || hasSlot('addonLeft')"
      class="input-group-prepend"
    >
      <span class="input-group-text">
        <slot name="addonLeft">
          <i :class="[addonLeftIcon]"></i>
        </slot>
      </span>
    </div>
    <slot :inputValue="inputValue" :updateValue="updateValue">
      <input
        :value="inputValue"
        v-bind="$attrs"
        class="form-control"
        :required="required"
        :class="[
          { 'is-valid': hasSuccess },
          inputClasses,
          { 'is-invalid': !!errorMessage },
        ]"
        aria-describedby="addon-right addon-left"
        v-on="listeners"
        @input="
          updateValue(
            $event.srcElement.type === 'checkbox'
              ? $event.target?.checked
              : $event.target.value
          )
        "
      />
    </slot>
    <div
      v-if="addonRightIcon || hasSlot('addonRight')"
      class="input-group-append"
    >
      <span class="input-group-text">
        <slot name="addonRight">
          <i :class="addonRightIcon"></i>
        </slot>
      </span>
    </div>
    <slot name="infoBlock"></slot>
    <slot name="helpBlock">
      <div
        v-show="errorMessage || meta.valid"
        class="invalid-feedback error-text"
        :class="{ 'mt-2': hasIcon }"
      >
        {{ errorMessage }}
      </div>
    </slot>
  </div>
</template>
<script>
import { useField, configure } from "vee-validate";
import { ref } from "vue";

configure({
  validateOnInput: true, // controls if `input` events should trigger validation with `handleChange` handler
  validateOnBlur: true,
  validateOnChange: true,
});

export default {
  name: "FgInput",
  inheritAttrs: false,
  props: {
    name: {
      type: String,
      default: "",
    },
    required: {
      type: Boolean,
      description: "Whether input is required (adds an asterix *)",
    },
    label: {
      type: String,
      description: "Input label (text before input)",
      default: "",
    },
    error: {
      type: String,
      description: "Input error (below input)",
      default: "",
    },
    labelClasses: {
      type: String,
      description: "Input label css classes",
      default: "",
    },
    inputClasses: {
      type: String,
      description: "Input css classes",
      default: "",
    },
    modelValue: {
      type: [String, Number, Array, Boolean, Object],
      description: "Input value",
      default: "",
    },
    addonRightIcon: {
      type: String,
      description: "Addon right icon",
      default: undefined,
    },
    addonLeftIcon: {
      type: String,
      description: "Addon left icon",
      default: undefined,
    },
  },
  emits: ["input", "focus", "blur", "update:modelValue"],
  setup(props) {
    const {
      value: inputValue,
      errorMessage,
      handleBlur,
      handleChange,
      meta,
      setTouched,
    } = useField(props.name, undefined, {
      initialValue: props.modelValue,
    });

    return {
      errorMessage,
      inputValue,
      handleBlur,
      handleChange,
      setTouched,
      meta,
      focused: ref(false),
    };
  },
  computed: {
    listeners() {
      return {
        ...this.$attrs,
        change: (args) => {
          this.handleChange(args);
        },
        input: this.handleChange,
        focus: this.onFocus,
        blur: this.onBlur,
      };
    },
    hasSuccess() {
      return this.meta.valid && this.meta.touched && !this.error;
    },
    hasIcon() {
      const { addonRight, addonLeft } = this.$slots;
      return (
        addonRight !== undefined ||
        addonLeft !== undefined ||
        this.addonRightIcon !== undefined ||
        this.addonLeftIcon !== undefined
      );
    },
  },
  watch: {
    errorMessage(newVal) {
      if (newVal) {
        this.hadError = true;
      }
    },
  },
  created() {},
  methods: {
    updateValue(evt) {
      this.inputValue = evt;
      this.$emit("update:modelValue", evt);
    },
    onFocus(evt) {
      this.focused = true;
      this.$emit("focus", evt);
      this.setTouched(true);
    },
    onBlur(evt) {
      this.focused = false;
      this.$emit("blur", evt);
      this.handleBlur(evt, true);
    },
  },
};
</script>
<style scoped>
.error-text {
  display: block;
}
</style>
