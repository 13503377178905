<template>
  <div class="row">
    <div class="col-lg-6 col-md-12">
      <card>
        <template #header>
          <h5>Your profile & Preferences</h5>
        </template>
        <tabs value="Description" tab-content-classes="text-center">
          <tab-pane key="profile" title="Profile">
            <div>
              <avatar :text="fullname"></avatar>
              <p>
                This is your avatar throughout JAICS. Users see you this way
              </p>
            </div>
            <div>
              <h4>Main settings</h4>
              <p>These are your profile information</p>

              <fg-input
                v-model="profile.firstName"
                type="text"
                label="First name"
                placeholder="Your first name"
                name="firstName"
                aria-placeholder="First name"
              ></fg-input>
              <fg-input
                v-model="profile.lastName"
                type="text"
                label="Last name"
                placeholder="Your last name"
                name="lastName"
                aria-placeholder="Last name"
              ></fg-input>
            </div>
          </tab-pane>
          <tab-pane key="preferences" title="Preferences">
            <p>You cannot change any preferences</p>
          </tab-pane>
        </tabs>
      </card>
    </div>
  </div>
</template>

<script>
import { defineComponent, computed } from "vue";
import {
  Tabs,
  TabPane,
  Card,
  Avatar,
  FgInput,
} from "src/components/UIComponents";
import { useStore } from "vuex";

export default defineComponent({
  components: {
    TabPane,
    Tabs,
    Card,
    Avatar,
    FgInput,
  },
  setup() {
    const store = useStore();

    const profile = store.state.user;

    return {
      profile,
      fullname: computed(() => profile.firstName + " " + profile.lastName),
    };
  },
});
</script>
