<template>
  <div class="wrapper">
    <side-bar type="sidebar" :sidebar-links="sidebar.sidebarLinks">
      <user-menu></user-menu>
    </side-bar>

    <div class="main-panel">
      <top-navbar></top-navbar>

      <dashboard-content @click="toggleSidebar"> </dashboard-content>

      <content-footer></content-footer>
    </div>
  </div>
</template>

<script>
import TopNavbar from "./TopNavbar.vue";
import ContentFooter from "./ContentFooter.vue";
import DashboardContent from "./Content.vue";
import UserMenu from "src/components/UIComponents/SidebarPlugin/UserMenu.vue";
//import { inject } from "vue";

export default {
  components: {
    TopNavbar,
    ContentFooter,
    DashboardContent,
    UserMenu,
  },
  inject: {
    sidebar: { default: null },
  },
  /*setup() {
    const sidebar = inject("sidebar");

    return {
      sidebar,
    };
  },*/
  methods: {
    toggleSidebar() {
      if (this.sidebar.showSidebar) {
        this.sidebar.displaySidebar(false);
      }
    },
  },
};
</script>

<style lang="scss"></style>
