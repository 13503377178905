<template>
  <li
    :class="{
      active: isActive || $route.fullPath.startsWith(link.path),
      divider: link.type === 'ruler',
    }"
  >
    <a
      v-if="isMenu"
      data-toggle="collapse"
      href="#"
      @click.prevent="collapseMenu"
    >
      <i :class="link.icon"></i>
      <p>
        {{ link.name }}
        <b class="caret" :class="{ rotated: !collapsed }"></b>
      </p>
    </a>
    <slot
      v-if="
        children.length === 0 &&
        !!link.path &&
        Object.entries(link).length !== 0
      "
    >
      <component
        :is="elementType(link, false)"
        :to="link.path"
        :class="{ active: link.active }"
        :target="link.target"
        :href="link.path"
        @click.stop="onItemClick"
      >
        <template v-if="addLink">
          <span class="sidebar-mini-icon">{{ link.name.substring(0, 1) }}</span>
          <span class="sidebar-normal">{{ link.name }}</span>
        </template>
        <template v-else>
          <i :class="link.icon"></i>
          <p>{{ link.name }}</p>
        </template>
      </component>
    </slot>
    <collapse-transition
      v-if="!(children.length === 0 && !!link.path) && link.type !== 'ruler'"
    >
      <div v-show="!collapsed" class="collapse-menu">
        <ul class="nav">
          <slot></slot>
        </ul>
      </div>
    </collapse-transition>
  </li>
</template>
<script>
import { Collapse as CollapseTransition } from "src/components/Transitions";

export default {
  components: {
    CollapseTransition,
  },
  provide() {
    return {
      isActive: false,
      addLink: this.addChild,
      removeLink: this.removeChild,
    };
  },
  inject: {
    sidebar: { default: null },
    addLink: { default: null },
    removeLink: { default: null },
    autoClose: { default: false },
  },
  props: {
    menu: {
      type: Boolean,
      default: false,
      description: "Whether is item a menu containing multiple items",
    },
    link: {
      type: Object,
      default: () => {
        return {
          name: "",
          path: "",
          children: [],
        };
      },
      description: "Link object",
    },
    index: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      children: [],
      collapsed: true,
    };
  },

  computed: {
    baseComponent() {
      //return this.isMenu || this.link.isRoute ? "li" : "router-link";
      return this.isMenu ? "li" : "router-link";
    },
    isRouterLink() {
      return !this.isMenu && this.isRoute(this.link.path);
    },
    isMenu() {
      return this.children.length > 0 || this.menu === true;
    },
    hasDefaultSlot() {
      return !!this.$slots.default;
    },
    isActive() {
      if (this.$route) {
        let matchingRoute = this.children.find((c) =>
          this.$route.path.startsWith(c.link.path)
        );
        if (matchingRoute !== undefined) {
          return true;
        }
      }
      return false;
    },
  },
  mounted() {
    if (this.addLink) {
      this.addLink(this);
    }
    if (this.$props.link.collapsed !== undefined) {
      this.collapsed = this.$props.link.collapsed;
    }
    if (this.isActive && this.isMenu) {
      this.collapsed = false;
    }
  },
  unmounted() {
    if (this.$el && this.$el.parentNode) {
      this.$el.parentNode.removeChild(this.$el);
    }
    if (this.removeLink) {
      this.removeLink(this);
    }
  },

  methods: {
    isRoute(path) {
      return path ? !!this.$router.resolve(path).name : false;
    },
    addChild(item) {
      //const index = this.$slots.default().indexOf(item.$el);
      const index = item.$props.index;
      this.children.splice(index, 0, item);
    },
    removeChild(item) {
      const tabs = this.children;
      const index = tabs.indexOf(item);
      tabs.splice(index, 1);
    },
    elementType(link, isParent = true) {
      //if (link.isRoute === false) {
      if (this.isRouterLink === false) {
        return isParent ? "li" : "a";
      } else {
        return "router-link";
      }
    },
    collapseMenu() {
      this.collapsed = !this.collapsed;
    },
    onItemClick() {
      if (this.autoClose) {
        this.sidebar.showSidebar = false;
      }
    },
  },
};
</script>
<style scoped>
.caret.rotated {
  transform: rotate(180deg);
}
</style>
