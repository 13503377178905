<template>
  <div
    v-show="active"
    :id="tabId"
    class="tab-pane fade"
    role="tabpanel"
    :aria-hidden="!active"
    :aria-labelledby="`step-${tabId}`"
    :class="{ 'active show': active }"
  >
    <slot></slot>
  </div>
</template>
<script>
export default {
  name: "WizardTab",
  inject: ["addTab", "removeTab"],
  props: {
    label: {
      type: String,
      description: "Wizard tab label (title)",
    },
    id: {
      type: String,
      description: "wizard tab id",
    },
    beforeChange: {
      type: Function,
      description:
        "Function to execute before switching to another tab. Can be Function<boolean> or Function<Promise<boolean>>",
    },
  },
  data() {
    return {
      active: false,
      checked: false,
      hasError: false,
      tabId: "",
    };
  },
  mounted() {
    this.addTab(this);
  },
  unmounted() {
    if (this.$el && this.$el.parentNode) {
      this.$el.parentNode.removeChild(this.$el);
    }
    this.removeTab(this);
  },
};
</script>
<style></style>
