import axios from "axios";
import * as types from "./mutation-types.js";
import { IOrder, IPerson } from "@/models/models";

const createConfig = (token: String) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-type": "application/json",
    },
  };
  return config;
};

const state = () => ({
  orders: [],
  order: {} as IOrder,
  persons: [],
});
const mutations = {
  [types.SET_ORDERS](state: any, orders: Array<IOrder>) {
    state.orders = orders;
  },
  [types.SET_ORDER](state: any, order: IOrder) {
    state.order = order;
  },
  [types.SET_ORDER_PERSONS](state: any, persons: Array<IPerson>) {
    state.persons = persons;
  },
  [types.SET_ORDER_PERSONS_STATS](state: any, stats: Array<any>) {
    state.persons?.forEach(
      (pEl: IPerson) =>
        (pEl.committedTime =
          stats.find((el) => el.personId === pEl.id)?.duration ?? 0)
    );
  },
};
const getters = {
  getOrders({ orders }: { orders: Array<IOrder> }) {
    return orders;
  },
  getOrder({ orders }: { orders: Array<IOrder> }) {
    return (id: Number) => orders.find((el) => el.id === id);
  },
};

const actions = {
  async fetchOrder({ commit, rootGetters, state }: any, orderId: Number) {
    try {
      return await axios
        .get(`orders/${orderId}`, {
          ...createConfig(rootGetters.getToken),
        })
        .then(({ data }) => {
          console.log("api response OK");
          commit(types.SET_ORDER, data.data);

          return { result: true };
        })
        .catch((error) => {
          console.error("api error" + error);
          return { result: false, message: error.message };
        });
    } catch (e) {
      console.error(e);
      if (e instanceof Error) {
        return { result: false, message: e.message };
      } else {
        return { result: false, message: "Unknown" };
      }
    }
  },
  async fetchOrders(
    { commit, rootGetters }: any,
    {
      page,
      perPage,
      searchTerm,
      searchFields,
      statusFilter,
      orderBy,
      sortOrder,
      contactId,
    }: {
      page: Number;
      perPage: Number;
      searchTerm: String;
      searchFields: Array<String>;
      statusFilter: Array<String>;
      orderBy: String;
      sortOrder: String;
      contactId: Number;
    }
  ) {
    try {
      return await axios
        .get("orders", {
          ...createConfig(rootGetters.getToken),
          params: {
            page,
            search_fields: searchFields,
            search_term: searchTerm,
            per_page: perPage,
            status: statusFilter,
            order_by: orderBy && sortOrder ? [orderBy, sortOrder] : [],
            contact_id: contactId,
          },
        })
        .then(({ data }) => {
          console.log("api response OK");
          commit(types.SET_ORDERS, data.data);

          return { total: data.meta.total };
        })
        .catch((error) => {
          console.error("api error" + error);
          return { result: false, message: error.message };
        });
    } catch (e) {
      console.error(e);
      if (e instanceof Error) {
        return { result: false, message: e.message };
      } else {
        return { result: false, message: "Unknown" };
      }
    }
  },
  async fetchOrderById({ commit, rootGetters, state }: any, orderId: Number) {
    const existingOrder = state.orders.find(
      (order: IOrder) => order.id === orderId
    );
    if (existingOrder) commit(types.SET_ORDERS, [existingOrder]);
    else {
      try {
        return await axios
          .get(`orders/${orderId}`, {
            ...createConfig(rootGetters.getToken),
          })
          .then(({ data }) => {
            console.log("api response OK");
            commit(types.SET_ORDERS, [data.data]);

            return { result: true };
          })
          .catch((error) => {
            console.error("api error" + error);
            return { result: false, message: error.message };
          });
      } catch (e) {
        console.error(e);
        if (e instanceof Error) {
          return { result: false, message: e.message };
        } else {
          return { result: false, message: "Unknown" };
        }
      }
    }
  },
  async createOrder({ rootGetters }: any, orderValues: IOrder) {
    try {
      return await axios
        .post("orders", orderValues, createConfig(rootGetters.getToken))
        .then(({ data }) => {
          console.log("api response OK", data);

          return { result: true, id: data.id };
        })
        .catch((error) => {
          console.error("api error" + error);
          return { result: false, message: error.message };
        });
    } catch (e) {
      console.error(e);
      if (e instanceof Error) {
        return { result: false, message: e.message };
      } else {
        return { result: false, message: "Unknown" };
      }
    }
  },
  async updateOrder(
    { rootGetters, commit }: any,
    { orderValues, id }: { orderValues: IOrder; id: Number }
  ) {
    try {
      return await axios
        .patch(`orders/${id}`, orderValues, createConfig(rootGetters.getToken))
        .then(({ data }) => {
          console.log("api response OK", data);

          commit(types.SET_ORDER, data);

          return { result: true };
        })
        .catch((error) => {
          console.error("api error" + error);
          return { result: false, message: error.message };
        });
    } catch (e) {
      console.error(e);
      if (e instanceof Error) {
        return { result: false, message: e.message };
      } else {
        return { result: false, message: "Unknown" };
      }
    }
  },
  async clearOrder({ rootGetters, commit }: any) {
    commit(types.SET_ORDER, {} as IOrder);
  },
  async fetchPersons(
    { commit, rootGetters, dispatch }: any,
    {
      orderId,
      page,
      perPage,
      orderBy,
      sortOrder,
      withStats,
    }: {
      orderId: Number;
      page: Number;
      perPage: Number;
      orderBy: String;
      sortOrder: String;
      withStats: Boolean;
    }
  ) {
    try {
      return await axios
        .get(`orders/${orderId}/persons`, {
          ...createConfig(rootGetters.getToken),
          params: {
            page,
            per_page: perPage,
            order_by: orderBy && sortOrder ? [orderBy, sortOrder] : [],
          },
        })
        .then(({ data }) => {
          console.log("api response OK");
          commit(types.SET_ORDER_PERSONS, data.data);

          if (withStats) {
            dispatch("fetchPersonsStatsForOrder", orderId);
          }

          return { total: data.meta.total };
        })
        .catch((error) => {
          console.error("api error" + error);
          return { result: false, message: error.message };
        });
    } catch (e) {
      console.error(e);
      if (e instanceof Error) {
        return { result: false, message: e.message };
      } else {
        return { result: false, message: "Unknown" };
      }
    }
  },
  async fetchPersonsStatsForOrder(
    { rootGetters, commit }: any,
    orderId: Number
  ) {
    try {
      await axios
        .get("report", {
          ...createConfig(rootGetters.getToken),
          params: {
            type: "hours",
            grouping: ["personId"],
            orderId: orderId,
          },
        })
        .then((res) => {
          commit(types.SET_ORDER_PERSONS_STATS, res.data.data);
        })
        .catch(() => {});
    } catch (e) {
      console.error(e);
      if (e instanceof Error) {
        return { result: false, message: e.message };
      } else {
        return { result: false, message: "Unknown" };
      }
    }
  },
  async assignPerson(
    { rootGetters }: any,
    {
      orderId,
      personId,
      amount,
    }: { orderId: Number; personId: Number; amount: Number }
  ) {
    try {
      return await axios
        .post(
          `orders/${orderId}/persons`,
          { estimatedTime: amount, person_id: personId },
          createConfig(rootGetters.getToken)
        )
        .then(({ data }) => {
          console.log("api response OK", data);

          return { result: true, id: data.id };
        })
        .catch((error) => {
          console.error("api error" + error);
          return { result: false, message: error.message };
        });
    } catch (e) {
      console.error(e);
      if (e instanceof Error) {
        return { result: false, message: e.message };
      } else {
        return { result: false, message: "Unknown" };
      }
    }
  },
  async unassignPerson(
    { rootGetters }: any,
    { orderId, personId }: { orderId: Number; personId: Number }
  ) {
    try {
      return await axios
        .delete(
          `orders/${orderId}/persons/${personId}`,
          createConfig(rootGetters.getToken)
        )
        .then(({ data, status }) => {
          console.log("api response OK", data);

          return { result: status == 202 || status == 204, id: data.id };
        })
        .catch((error) => {
          console.error("api error" + error);
          return { result: false, message: error.message };
        });
    } catch (e) {
      console.error(e);
      if (e instanceof Error) {
        return { result: false, message: e.message };
      } else {
        return { result: false, message: "Unknown" };
      }
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
