<template>
  <div class="user">
    <div class="photo">
      <avatar size="small" :text="fullname" alt="user avatar" />
    </div>
    <div class="info">
      <a
        data-toggle="collapse"
        :aria-expanded="!isClosed"
        href="#"
        @click.prevent="toggleMenu"
      >
        <span>
          {{ user?.firstName }} {{ user?.lastName }}
          <b class="caret"></b>
        </span>
      </a>
      <div class="clearfix"></div>
      <div>
        <collapse-transition>
          <ul v-show="!isClosed" class="nav nav-menu">
            <li>
              <router-link to="/profile">
                <span class="sidebar-mini-icon">Mp</span>
                <span class="sidebar-normal">My Profile</span>
              </router-link>
            </li>
          </ul>
        </collapse-transition>
      </div>
    </div>
  </div>
</template>
<script>
import { Collapse as CollapseTransition } from "src/components/Transitions";
import { useStore } from "vuex";
import { computed } from "vue";
import { Avatar } from "src/components/UIComponents";

export default {
  components: {
    CollapseTransition,
    Avatar,
  },
  data() {
    const store = useStore();
    const user = computed(() => store.getters["getUser"]);

    return {
      user,
      isClosed: true,
      fullname: user.value.firstName + " " + user.value.lastName,
    };
  },
  methods: {
    toggleMenu() {
      this.isClosed = !this.isClosed;
    },
  },
};
</script>
<style scoped>
.nav.nav-menu {
  margin-top: 0;
}
</style>
