const userSidebar = {
  name: "userSidebar",
  roles: ["user", "seller", "admin"],
  links: [
    {
      name: "Dashboard",
      icon: "nc-icon nc-bank",
      path: "/dashboard",
    },
    {
      name: "Contacts",
      icon: "nc-icon nc-app",
      children: [
        { name: "Customers", path: "/contacts/customer" },
        { name: "Suppliers", path: "/contacts/supplier" },
        { name: "Contact", path: "/contacts/contact" },
      ],
    },
    {
      name: "Orders",
      icon: "nc-icon nc-paper",
      children: [
        { name: "Orders", path: "/orders/overview" },
        { name: "Offers", path: "/orders/offers" },
      ],
    },
    {
      name: "Activities",
      icon: "nc-icon nc-time-alarm",
      children: [
        { name: "Activities", path: "/activities/overview" },
        { name: "Balance review", path: "/activities/balance" },
      ],
    },
    {
      name: "Persons",
      icon: "nc-icon nc-badge",
      path: "/persons",
    },
    {
      name: "Reports",
      icon: "nc-icon nc-chart-bar-32",
      path: "/reports",
    },
  ],
};
const adminSidebar = {
  name: "adminSidebar",
  roles: ["superadmin"],
  links: [
    { name: "ruler", type: "ruler" }, //Horizontal rule
    {
      name: "Administration",
      icon: "nc-icon nc-compass-05",
      children: [
        {
          name: "Users",
          path: "/admin/users",
        },
      ],
    },
  ],
};
const uiKitSidebar = {
  name: "uiKitSidebar",
  roles: ["developer"],
  links: [
    /* eslint-disable indent */
    ...(() => {
      return process.env.NODE_ENV !== "production"
        ? new Array(
            { name: "ruler", type: "ruler" }, //Horizontal rule,
            {
              name: "Pages",
              icon: "nc-icon nc-book-bookmark",
              children: [
                {
                  name: "Timeline",
                  path: "/pages/timeline",
                },
                {
                  name: "User Profile",
                  path: "/pages/user",
                },
                {
                  name: "Login",
                  path: "/login",
                },
                {
                  name: "Register",
                  path: "/register",
                },
                {
                  name: "Lock",
                  path: "/lock",
                },
              ],
            },
            {
              name: "Components",
              icon: "nc-icon nc-layout-11",
              children: [
                {
                  name: "Buttons",
                  path: "/components/buttons",
                },
                {
                  name: "Grid System",
                  path: "/components/grid-system",
                },
                {
                  name: "Panels",
                  path: "/components/panels",
                },
                {
                  name: "Sweet Alert",
                  path: "/components/sweet-alert",
                },
                {
                  name: "Notifications",
                  path: "/components/notifications",
                },
                {
                  name: "Icons",
                  path: "/components/icons",
                },
                {
                  name: "Typography",
                  path: "/components/typography",
                },
              ],
            },
            {
              name: "Forms",
              icon: "nc-icon nc-ruler-pencil",
              children: [
                {
                  name: "Regular Forms",
                  path: "/forms/regular",
                },
                {
                  name: "Extended Forms",
                  path: "/forms/extended",
                },
                {
                  name: "Validation Forms",
                  path: "/forms/validation",
                },
                {
                  name: "Wizard",
                  path: "/forms/wizard",
                },
              ],
            },
            {
              name: "Table List",
              icon: "nc-icon nc-single-copy-04",
              collapsed: true,
              children: [
                {
                  name: "Regular Tables",
                  path: "/table-list/regular",
                },
                {
                  name: "Extended Tables",
                  path: "/table-list/extended",
                },
                {
                  name: "Paginated Tables",
                  path: "/table-list/paginated",
                },
              ],
            },
            {
              name: "Maps",
              icon: "nc-icon nc-pin-3",
              children: [
                {
                  name: "Google Maps",
                  path: "/maps/google",
                },
                {
                  name: "Full Screen Maps",
                  path: "/maps/full-screen",
                },
                {
                  name: "Vector Maps",
                  path: "/maps/vector-map",
                },
              ],
            },
            {
              name: "Widgets",
              icon: "nc-icon nc-box",
              path: "/admin/widgets",
            },
            {
              name: "Charts",
              icon: "nc-icon nc-chart-bar-32",
              path: "/charts",
            },
            {
              name: "Calendar",
              icon: "nc-icon nc-calendar-60",
              path: "/calendar",
            }
          )
        : new Array();
    })(),
    /* eslint-enable indent */
  ],
};

export { userSidebar, adminSidebar, uiKitSidebar };
