<template>
  <el-tag :type="finalType" :theme="theme" :class="['custom', customClass]"
    ><slot></slot
  ></el-tag>
</template>
<script>
import { ElTag } from "element-plus";
import { computed } from "vue";

const elPlusTypes = ["success", "info", "warning", "danger"];

export default {
  name: "PTag",
  components: {
    ElTag,
  },
  props: {
    type: {
      type: String,
      default: "",
      description:
        "Type of the tag as for element-plus Tag component documentation",
    },
    theme: {
      type: String,
      default: "plain",
      description:
        "Theme of the tag as for element-plus Tag component documentation",
    },
  },
  setup(props) {
    return {
      finalType: computed(
        () => elPlusTypes.find((el) => el == props.type) ?? ""
      ),
      customClass: computed(() =>
        !elPlusTypes.find((el) => el == props.type)
          ? `el-tag--${props.type}`
          : ""
      ),
    };
  },
};
</script>
<style scoped></style>
