import axios from "axios";
import * as types from "./mutation-types.js";
import { IContact } from "@/models/models";

const createConfig = (token: string) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-type": "application/json",
    },
  };
  return config;
};

const state = () => ({
  contact: {} as IContact, //Current resource being show/edited in a view
  contacts: [], //A collection of resources that can be read
});

const mutations = {
  [types.SET_CONTACTS](state: any, contacts: Array<IContact>) {
    state.contacts = contacts;
  },
  [types.SET_CONTACT](state: any, contact: IContact) {
    state.contact = contact;
  },
};

const getters = {
  getContacts({ contacts }: { contacts: Array<IContact> }) {
    return contacts;
  },
  getContact({ contacts }: { contacts: Array<IContact> }) {
    return (id: Number) => contacts.find((el) => el.id === id);
  },
  getContactsByType({ contacts }: { contacts: Array<IContact> }) {
    return (type: String) => contacts.filter((el) => el.type === type);
  },
};

const actions = {
  async fetchContacts(
    { commit, rootGetters }: any,
    {
      type,
      page,
      perPage,
      searchTerm,
      searchFields,
      orderBy,
      sortOrder,
    }: {
      type: String;
      page: Number;
      perPage: Number;
      searchTerm: String;
      searchFields: Array<String>;
      orderBy: String;
      sortOrder: String;
    }
  ) {
    try {
      return await axios
        .get("contacts", {
          ...createConfig(rootGetters.getToken),
          params: {
            page: page,
            type: type,
            search_fields: searchFields,
            search_term: searchTerm,
            per_page: perPage,
            order_by: orderBy && sortOrder ? [orderBy, sortOrder] : [],
          },
        })
        .then(({ data }) => {
          console.log("api response OK");
          commit(types.SET_CONTACTS, data.data);

          return { total: data.meta.total };
        })
        .catch((error) => {
          console.error("api error" + error);
          return { result: false, message: error.message };
        });
    } catch (e) {
      console.error(e);
      if (e instanceof Error) {
        return { result: false, message: e.message };
      } else {
        return { result: false, message: "Unknown" };
      }
    }
  },
  async fetchContactById(
    { commit, rootGetters, state }: any,
    contactId: Number
  ) {
    const existingContact = state.contacts.find(
      (contact: IContact) => contact.id === contactId
    );
    if (existingContact) commit(types.SET_CONTACTS, [existingContact]);
    else {
      try {
        return await axios
          .get(`contacts/${contactId}`, {
            ...createConfig(rootGetters.getToken),
          })
          .then(({ data }) => {
            console.log("api response OK");
            commit(types.SET_CONTACTS, [data.data]);

            return { result: true };
          })
          .catch((error) => {
            console.error("api error" + error);
            return { result: false, message: error.message };
          });
      } catch (e) {
        console.error(e);
        if (e instanceof Error) {
          return { result: false, message: e.message };
        } else {
          return { result: false, message: "Unknown" };
        }
      }
    }
  },
  async fetchContact({ commit, rootGetters, state }: any, contactId: Number) {
    const existingContact = state.contact?.id === contactId;
    if (!existingContact) {
      try {
        return await axios
          .get(`contacts/${contactId}`, {
            ...createConfig(rootGetters.getToken),
          })
          .then(({ data }) => {
            console.log("api response OK");
            commit(types.SET_CONTACT, data.data);

            return { result: true };
          })
          .catch((error) => {
            console.error("api error" + error);
            return { result: false, message: error.message };
          });
      } catch (e) {
        console.error(e);
        if (e instanceof Error) {
          return { result: false, message: e.message };
        } else {
          return { result: false, message: "Unknown" };
        }
      }
    }
  },
  async createContact({ rootGetters }: any, contactValues: IContact) {
    try {
      return await axios
        .post("contacts", contactValues, createConfig(rootGetters.getToken))
        .then(({ data }) => {
          console.log("api response OK", data);

          return { result: true, id: data.id };
        })
        .catch((error) => {
          console.error("api error" + error);
          return { result: false, message: error.message };
        });
    } catch (e) {
      console.error(e);
      if (e instanceof Error) {
        return { result: false, message: e.message };
      } else {
        return { result: false, message: "Unknown" };
      }
    }
  },
  async updateContact(
    { rootGetters, commit }: any,
    { contactValues, id }: { contactValues: IContact; id: Number }
  ) {
    try {
      return await axios
        .patch(
          `contacts/${id}`,
          contactValues,
          createConfig(rootGetters.getToken)
        )
        .then(({ data }) => {
          console.log("api response OK", data);

          commit(types.SET_CONTACTS, [data]);

          return { result: true };
        })
        .catch((error) => {
          console.error("api error" + error);
          return { result: false, message: error.message };
        });
    } catch (e) {
      console.error(e);
      if (e instanceof Error) {
        return { result: false, message: e.message };
      } else {
        return { result: false, message: "Unknown" };
      }
    }
  },
  async clearContact({ rootGetters, commit }: any) {
    commit(types.SET_CONTACT, {} as IContact);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
