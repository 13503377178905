import axios from "axios";
import * as types from "./mutation-types.js";
import { IActivity } from "@/models/models";

interface Filter {
  key: String;
  value: String;
}

const createConfig = (token: string) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-type": "application/json",
    },
  };
  return config;
};

const state = () => ({
  activities: [],
});
const mutations = {
  [types.SET_ACTIVITIES](state: any, activities: Array<IActivity>) {
    activities.forEach((el) => (el.date = el.date?.split(" ")[0]));
    state.activities = activities;
  },
};
const getters = {
  getActivities({ activities }: { activities: Array<IActivity> }) {
    return (filters: Array<Filter>) =>
      activities.filter((activity) =>
        filters.every((filter) => activity[filter.key] === filter.value)
      );
  },
  getActivitiesByType({ activities }: { activities: Array<IActivity> }) {
    return (type: String) =>
      activities.filter((activity) => activity.type === type);
  },
  getActivity({ activities }: { activities: Array<IActivity> }) {
    return (id: Number) => activities.find((el) => el.id === id);
  },
};

const actions = {
  async fetchActivities(
    { commit, rootGetters }: any,
    {
      page,
      perPage,
      searchTerm,
      searchFields,
      orderId,
      sorting,
      billable = null,
      billed = null,
      dateRange,
    }: {
      page: Number;
      perPage: Number;
      searchTerm: String;
      searchFields: Array<String>;
      orderId: number;
      sorting: Array<any>;
      billable: Boolean | null;
      billed: Boolean | null;
      dateRange: Array<Date>; //[startDate,endDate]
    }
  ) {
    try {
      return await axios
        .get("activities", {
          ...createConfig(rootGetters.getToken),
          params: {
            page: page,
            search_fields: searchFields,
            search_term: searchTerm,
            per_page: perPage,
            orderId: orderId,
            order_by: [
              ...(sorting?.map((el) => el.column) ?? []),
              ...(sorting?.map((el) => el.order) ?? []),
            ],
            billable: billable,
            billed: billed,
            dateStart: dateRange?.[0],
            dateEnd: dateRange?.[1],
          },
        })
        .then(({ data }) => {
          console.log("api response OK");
          commit(types.SET_ACTIVITIES, data.data);

          return { total: data.meta.total };
        })
        .catch((error) => {
          console.error("api error" + error);
          return { result: false, message: error.message };
        });
    } catch (e) {
      console.error(e);
      if (e instanceof Error) {
        return { result: false, message: e.message };
      } else {
        return { result: false, message: "Unknown" };
      }
    }
  },
  async createActivity({ rootGetters }: any, activityValues: IActivity) {
    try {
      return await axios
        .post("activities", activityValues, createConfig(rootGetters.getToken))
        .then(({ data }) => {
          console.log("api response OK", data);

          return { result: true, id: data.id };
        })
        .catch((error) => {
          console.error("api error" + error);
          return { result: false, message: error.message };
        });
    } catch (e) {
      console.error(e);
      if (e instanceof Error) {
        return { result: false, message: e.message };
      } else {
        return { result: false, message: "Unknown" };
      }
    }
  },
  async deleteActivity({ rootGetters }: any, activityId: number) {
    try {
      return await axios
        .delete(`activities/${activityId}`, createConfig(rootGetters.getToken))
        .then(({ data }) => {
          console.log("api response OK", data);

          return { result: true, id: data.id };
        })
        .catch((error) => {
          console.error("api error" + error);
          return { result: false, message: error.message };
        });
    } catch (e) {
      console.error(e);
      if (e instanceof Error) {
        return { result: false, message: e.message };
      } else {
        return { result: false, message: "Unknown" };
      }
    }
  },
  async massBill({ rootGetters }: any, activities: Array<Number>) {
    try {
      return await axios
        .post(
          "activities/mass_update",
          { ids: activities, billed: 1 },
          createConfig(rootGetters.getToken)
        )
        .then(({ data }) => {
          console.log("api response OK", data);

          return { result: true, id: data.id };
        })
        .catch((error) => {
          console.error("api error" + error);
          return { result: false, message: error.message };
        });
    } catch (e) {
      console.error(e);
      if (e instanceof Error) {
        return { result: false, message: e.message };
      } else {
        return { result: false, message: "Unknown" };
      }
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
