<template>
  <div class="login-page">
    <app-navbar></app-navbar>
    <div class="wrapper wrapper-full-page">
      <div class="full-page login-page section-image">
        <!--   you can change the color of the filter page using: data-color="blue | azure | green | orange | red | purple" -->
        <div class="content">
          <div class="container">
            <div class="col-lg-4 col-md-6 ml-auto mr-auto">
              <wizard ref="wizard" :show-navigation="false" :hide-finish="true">
                <template #header>
                  <h3 class="header text-center">Login</h3>
                </template>
                <wizard-tab
                  :before-change="
                    async () => (await validateEmail()).errors.length == 0
                  "
                >
                  <div>
                    <div class="text-center mb-4">
                      Prompt the email used during registration
                    </div>
                    <fg-input
                      addon-left-icon="nc-icon nc-single-02"
                      name="email"
                      placeholder="email@..."
                      autocomplete="email"
                      @keyup.enter="$refs.wizard?.nextTab"
                    ></fg-input>
                    <label>Forgot email?</label>
                  </div>
                </wizard-tab>

                <wizard-tab>
                  <fg-input
                    addon-left-icon="nc-icon nc-single-02"
                    name="email"
                    placeholder="email@..."
                    autocomplete="email"
                  ></fg-input>

                  <fg-input
                    addon-left-icon="nc-icon nc-key-25"
                    name="password"
                    placeholder="Password"
                    type="password"
                    autocomplete="current-password"
                  >
                  </fg-input>
                  <br />
                  <fg-input name="rememberMe">
                    <template #default="slotProps">
                      <p-checkbox
                        :model-value="slotProps.inputValue"
                        @change="slotProps.updateValue($event.returnValue)"
                      >
                        Remember me
                      </p-checkbox>
                    </template>
                  </fg-input>
                  <p-button
                    native-type="submit"
                    type="warning"
                    round
                    block
                    class="mb-3 mt-3"
                    @click="onSubmit()"
                    >Login</p-button
                  >
                  <span>{{ errorMessage }}</span>
                </wizard-tab>
              </wizard>
            </div>
          </div>
        </div>
        <app-footer></app-footer>
        <div
          class="full-page-background"
          style="background-image: url(static/img/background/background-2.jpg)"
        ></div>
      </div>
    </div>
  </div>
</template>
<script>
import { Checkbox, Button } from "src/components/UIComponents";
import AppNavbar from "./Layout/AppNavbar";
import AppFooter from "./Layout/AppFooter";
import { useForm, useField } from "vee-validate";
import { object, string, bool } from "yup";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { ref } from "vue";
import { Wizard, WizardTab } from "src/components/UIComponents";

export default {
  components: {
    AppNavbar,
    AppFooter,
    [Checkbox.name]: Checkbox,
    [Button.name]: Button,
    Wizard,
    WizardTab,
  },
  setup() {
    const router = useRouter();
    const store = useStore();

    const schema = object().shape({
      email: string().email().required(),
      password: string().min(6).required(),
      rememberMe: bool(),
    });

    const errorMessage = ref("");

    const { errors, handleSubmit } = useForm({
      validationSchema: schema,
      initialValues: {
        email: "",
        password: "",
        rememberMe: false,
      },
    });

    const { validate: validateEmail } = useField("email");

    function onInvalidSubmit() {
      console.log("invalid");
    }

    const onSubmit = handleSubmit((values, { resetForm }) => {
      errorMessage.value = "";
      store
        .dispatch("authenticate", values)
        .then(({ result /*, message*/ }) => {
          if (result === true) {
            // reset the form and the field values to their initial values
            resetForm();

            router.push({ name: "root" });
          } else {
            errorMessage.value = "Authentication failed";
          }
        });
    }, onInvalidSubmit);

    return {
      schema,
      errors,
      onSubmit,
      errorMessage,
      validateEmail,
    };
  },
  beforeUnmount() {
    this.closeMenu();
  },
  methods: {
    toggleNavbar() {
      document.body.classList.toggle("nav-open");
    },
    closeMenu() {
      document.body.classList.remove("nav-open");
      document.body.classList.remove("off-canvas-sidebar");
    },
    login() {
      // handle login here
    },
  },
};
</script>
<style></style>
