import axios from "axios";
import * as types from "./mutation-types.js";
import { IUser } from "@/models/models.js";

const createConfig = (token: String) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-type": "application/json",
    },
  };
  return config;
};

const state = () => ({
  users: [],
  user: {} as IUser,
});
const mutations = {
  [types.SET_USERS](state: any, users: Array<IUser>) {
    state.users = users;
  },
  [types.SET_USER](state: any, user: IUser) {
    state.user = user;
  },
};
const getters = {
  getUsers({ users }: { users: Array<IUser> }) {
    return users;
  },
  getUser({ users }: { users: Array<IUser> }) {
    return (id: Number) => users.find((el: IUser) => el.id === id);
  },
};

const actions = {
  async fetchUsers(
    { commit, rootGetters }: any,
    {
      page,
      perPage,
      searchTerm,
      searchFields,
      orderBy,
      sortOrder,
    }: {
      page: Number;
      perPage: Number;
      searchTerm: String;
      searchFields: Array<String>;
      orderBy: String;
      sortOrder: String;
    }
  ) {
    try {
      return await axios
        .get("users", {
          ...createConfig(rootGetters.getToken),
          params: {
            page: page,
            search_fields: searchFields,
            search_term: searchTerm,
            per_page: perPage,
            order_by: orderBy && sortOrder ? [orderBy, sortOrder] : [],
          },
        })
        .then(({ data }) => {
          console.log("api response OK");
          commit(types.SET_USERS, data.data);

          return { total: data.meta.total };
        })
        .catch((error) => {
          console.error("api error" + error);
          return { result: false, message: error.message };
        });
    } catch (e) {
      console.error(e);
      if (e instanceof Error) {
        return { result: false, message: e.message };
      } else {
        return { result: false, message: "Unknown" };
      }
    }
  },
  async fetchUser({ commit, rootGetters }: any, userId: Number) {
    try {
      return await axios
        .get(`users/${userId}`, {
          ...createConfig(rootGetters.getToken),
        })
        .then(({ data }) => {
          console.log("api response OK");
          commit(types.SET_USER, data.data);

          return { result: true };
        })
        .catch((error) => {
          console.error("api error" + error);
          return { result: false, message: error.message };
        });
    } catch (e) {
      console.error(e);
      if (e instanceof Error) {
        return { result: false, message: e.message };
      } else {
        return { result: false, message: "Unknown" };
      }
    }
  },
  async createUser(
    { rootGetters }: any,
    { userValues, roleValues }: { userValues: IUser; roleValues: Array<String> }
  ) {
    try {
      return await axios
        .post(
          "users",
          { ...userValues, roles: roleValues, password: "password" },
          createConfig(rootGetters.getToken)
        )
        .then(({ data }) => {
          console.log("api response OK", data);

          return { result: true, id: data.id };
        })
        .catch((error) => {
          console.error("api error" + error);
          return { result: false, message: error.message };
        });
    } catch (e) {
      console.error(e);
      if (e instanceof Error) {
        return { result: false, message: e.message };
      } else {
        return { result: false, message: "Unknown" };
      }
    }
  },
  async modifyUser(
    { rootGetters, commit }: any,
    {
      id,
      userValues,
      roleValues,
    }: { id: number; userValues: IUser; roleValues: Array<String> }
  ) {
    try {
      return await axios
        .patch(
          `users/${id}`,
          { ...userValues, ...roleValues },
          createConfig(rootGetters.getToken)
        )
        .then(({ data }) => {
          console.log("api response OK", data);

          commit(types.SET_USERS, [data]);

          return { result: true, id: data.id };
        })
        .catch((error) => {
          console.error("api error" + error);
          return { result: false, message: error.message };
        });
    } catch (e) {
      console.error(e);
      if (e instanceof Error) {
        return { result: false, message: e.message };
      } else {
        return { result: false, message: "Unknown" };
      }
    }
  },
  async clearUser({ rootGetters, commit }: any) {
    commit(types.SET_USER, {} as IUser);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
