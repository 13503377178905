<template>
  <footer class="footer footer-black footer-white">
    <div class="container-fluid">
      <div class="row">
        <nav class="footer-nav">
          <ul>
            <li>
              <a href="https://www.idealize.srl" target="_blank" rel="noopener"
                >Idealize srl</a
              >
            </li>
            <li>
              <a href="http://blog.idealize.srl/" target="_blank" rel="noopener"
                >Blog</a
              >
            </li>
            <li>
              <a
                href="https://www.idealize.srl/license"
                target="_blank"
                rel="noopener"
                >Licenses</a
              >
            </li>
          </ul>
        </nav>
        <div class="credits ml-auto">
          <div class="copyright">
            &copy; Coded with
            <i class="fa fa-heart heart"></i> by
            <a
              href="https://www.idealize.srl/?ref=pdf-vuejs"
              target="_blank"
              rel="noopener"
              >Idealize srl</a
            >.
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>
<script>
export default {
  name: "MainFooter",
};
</script>
<style></style>
