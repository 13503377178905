<template>
  <div :class="[direction, `trend-arrows--${size}`]"></div>
</template>

<script>
import { defineComponent, ref } from "vue";

export default defineComponent({
  props: {
    newValue: {
      type: Number,
    },
    oldValue: {
      type: Number,
    },
    size: {
      type: String,
      default: "small",
    },
  },
  setup(props) {
    const direction = ref("arrow-up");

    if (props.newValue < props.oldValue) {
      direction.value = "arrow-down";
    }

    return {
      direction,
    };
  },
});
</script>

<style scoped>
.trend-arrows--small {
}
.trend-arrows--large {
}
</style>
