<template>
  <div
    class="sidebar"
    :data-color="backgroundColor"
    :data-active-color="activeColor"
  >
    <div class="logo">
      <a class="simple-text logo-mini" aria-label="sidebar mini logo" href="/">
        <div class="logo-img">
          <img :src="logo" alt="" />
        </div>
      </a>
      <a class="simple-text logo-normal" href="/">
        {{ title }}
      </a>
    </div>
    <perfect-scrollbar ref="sidebarScrollArea" class="sidebar-wrapper">
      <slot> </slot>
      <ul class="nav">
        <slot name="links">
          <template v-for="linkItem in sidebarLinks" :key="linkItem.name">
            <template v-if="checkRoles(linkItem.roles)">
              <sidebar-item
                v-for="(link, index) in linkItem.links"
                :key="link.name + index"
                :link="link"
              >
                <sidebar-item
                  v-for="(subLink, subIndex) in link.children"
                  :key="subLink.name + subIndex"
                  :link="subLink"
                  :index="subIndex"
                >
                </sidebar-item>
              </sidebar-item>
            </template>
          </template>
        </slot>
      </ul>
    </perfect-scrollbar>
  </div>
</template>
<script>
import "perfect-scrollbar/css/perfect-scrollbar.css";
export default {
  inject: {
    sidebar: { default: null },
  },
  provide() {
    return {
      autoClose: this.autoClose,
    };
  },
  props: {
    title: {
      type: String,
      default: "JAICS",
      description: "Sidebar title",
    },
    backgroundColor: {
      type: String,
      default: "black",
      validator: (value) => {
        let acceptedValues = ["white", "brown", "black"];
        return acceptedValues.indexOf(value) !== -1;
      },
      description: "Sidebar background color (white|brown|black)",
    },
    activeColor: {
      type: String,
      default: "success",
      validator: (value) => {
        let acceptedValues = [
          "primary",
          "info",
          "success",
          "warning",
          "danger",
        ];
        return acceptedValues.indexOf(value) !== -1;
      },
      description:
        "Sidebar active text color (primary|info|success|warning|danger)",
    },
    logo: {
      type: String,
      default: "static/img/vue-logo.png",
      description: "Sidebar Logo",
    },
    sidebarLinks: {
      type: Array,
      default: () => [],
      description:
        "Sidebar links. Can be also provided as children components (sidebar-item)",
    },
    autoClose: {
      type: Boolean,
      default: true,
    },
  },
  mounted() {
    this.initScrollBarAsync();
  },
  beforeUnmount() {
    if (this.sidebar.showSidebar) {
      this.sidebar.showSidebar = false;
    }
  },
  methods: {
    checkRoles(roles) {
      return (
        this.$store.state.user.roles.filter((role) => roles.includes(role))
          .length > 0
      );
    },
    async initScrollBarAsync() {
      let isWindows = navigator.platform.startsWith("Win");
      if (!isWindows) {
        return;
      }
      //TODO disable perfect scrollbar on mac
      //const PerfectScroll = await import("vue3-perfect-scrollbar");
      //PerfectScroll.initialize(this.$refs.sidebarScrollArea);
    },
  },
};
</script>
<style>
@media (min-width: 992px) {
  .navbar-search-form-mobile,
  .nav-mobile-menu {
    display: none;
  }
}
</style>
