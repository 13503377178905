import axios from "axios";
import * as types from "./mutation-types.js";
import { IPerson, IOrder } from "@/models/models";

const createConfig = (token: String) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-type": "application/json",
    },
  };
  return config;
};

const state = () => ({
  persons: Array<IPerson>(),
  person: {} as IPerson,
  orders: Array<Array<IOrder>>(),
});
const mutations = {
  [types.SET_PERSONS](state: any, persons: Array<IPerson>) {
    state.persons = persons;
  },
  [types.SET_PERSON](state: any, person: IPerson) {
    state.person = person;
  },
  [types.SET_PERSON_ORDERS](
    state: any,
    { orders }: { orders: Array<Array<IOrder>> }
  ) {
    state.orders = orders;
  },
};
const getters = {
  getPersons({ persons }: { persons: Array<Array<IPerson>> }) {
    return persons.reduce((prev, current) => prev.concat(current), []);
  },
  getPerson({ persons }: { persons: Array<Array<IPerson>> }) {
    return (id: Number) =>
      persons
        .reduce((prev, current) => prev.concat(current), [])
        .find((el) => el.id === id);
  },
};

const actions = {
  async fetchPersons(
    { commit, rootGetters }: any,
    {
      page,
      perPage,
      searchTerm,
      searchFields,
      orderBy,
      sortOrder,
    }: {
      page: Number;
      perPage: number;
      searchTerm: String;
      searchFields: Array<String>;
      orderBy: String;
      sortOrder: String;
    }
  ) {
    try {
      return await axios
        .get("persons", {
          ...createConfig(rootGetters.getToken),
          params: {
            page,
            search_fields: searchFields,
            search_term: searchTerm,
            per_page: perPage,
            order_by: orderBy && sortOrder ? [orderBy, sortOrder] : [],
          },
        })
        .then(({ data }) => {
          console.log("api response OK");
          commit(types.SET_PERSONS, data.data);

          return { total: data.meta.total };
        })
        .catch((error) => {
          console.error("api error" + error);
          return { result: false, message: error.message };
        });
    } catch (e) {
      console.error(e);
      if (e instanceof Error) {
        return { result: false, message: e.message };
      } else {
        return { result: false, message: "Unknown" };
      }
    }
  },
  async fetchPerson({ commit, rootGetters }: any, personId: Number) {
    try {
      return await axios
        .get(`persons/${personId}`, {
          ...createConfig(rootGetters.getToken),
        })
        .then(({ data }) => {
          console.log("api response OK");
          commit(types.SET_PERSON, data.data);

          return { result: true };
        })
        .catch((error) => {
          console.error("api error" + error);
          return { result: false, message: error.message };
        });
    } catch (e) {
      console.error(e);
      if (e instanceof Error) {
        return { result: false, message: e.message };
      } else {
        return { result: false, message: "Unknown" };
      }
    }
  },
  async fetchPersonById({ commit, rootGetters }: any, personId: Number) {
    try {
      return await axios
        .get(`persons/${personId}`, {
          ...createConfig(rootGetters.getToken),
        })
        .then(({ data }) => {
          console.log("api response OK");
          commit(types.SET_PERSONS, [data.data]);

          return { result: true };
        })
        .catch((error) => {
          console.error("api error" + error);
          return { result: false, message: error.message };
        });
    } catch (e) {
      console.error(e);
      if (e instanceof Error) {
        return { result: false, message: e.message };
      } else {
        return { result: false, message: "Unknown" };
      }
    }
  },
  async createPerson({ rootGetters }: any, personValues: IPerson) {
    try {
      return await axios
        .post("persons", personValues, createConfig(rootGetters.getToken))
        .then(({ data }) => {
          console.log("api response OK", data);

          return { result: true, id: data.id };
        })
        .catch((error) => {
          console.error("api error" + error);
          return { result: false, message: error.message };
        });
    } catch (e) {
      console.error(e);
      if (e instanceof Error) {
        return { result: false, message: e.message };
      } else {
        return { result: false, message: "Unknown" };
      }
    }
  },
  async updatePerson(
    { rootGetters, commit }: any,
    { personValues, id }: { personValues: IPerson; id: Number }
  ) {
    try {
      return await axios
        .patch(
          `persons/${id}`,
          personValues,
          createConfig(rootGetters.getToken)
        )
        .then(({ data }) => {
          console.log("api response OK", data);

          commit(types.SET_PERSON, data);

          return { result: true };
        })
        .catch((error) => {
          console.error("api error" + error);
          return { result: false, message: error.message };
        });
    } catch (e) {
      console.error(e);
      if (e instanceof Error) {
        return { result: false, message: e.message };
      } else {
        return { result: false, message: "Unknown" };
      }
    }
  },
  async fetchOrders(
    { commit, rootGetters, dispatch }: any,
    {
      personId,
      page,
      perPage,
      searchTerm,
      searchFields,
      orderBy,
      sortOrder,
      withStats,
    }: {
      personId: Number;
      page: Number;
      perPage: number;
      searchTerm: String;
      searchFields: Array<String>;
      orderBy: String;
      sortOrder: String;
      withStats: Boolean;
    }
  ) {
    try {
      return await axios
        .get(`persons/${personId}/orders`, {
          ...createConfig(rootGetters.getToken),
          params: {
            page,
            per_page: perPage,
            order_by: orderBy && sortOrder ? [orderBy, sortOrder] : [],
          },
        })
        .then(({ data }) => {
          console.log("api response OK");
          commit(types.SET_PERSON_ORDERS, {
            orders: data.data,
          });

          return { total: data.meta.total };
        })
        .catch((error) => {
          console.error("api error" + error);
          return { result: false, message: error.message };
        });
    } catch (e) {
      console.error(e);
      if (e instanceof Error) {
        return { result: false, message: e.message };
      } else {
        return { result: false, message: "Unknown" };
      }
    }
  },
  async clearPerson({ rootGetters, commit }: any) {
    commit(types.SET_PERSON, {} as IPerson);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
