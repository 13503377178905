<template>
  <component
    :is="tag"
    v-click-outside="closeDropDown"
    class="dropdown"
    :class="[{ show: isDropdownOpen }]"
    aria-haspopup="true"
    :aria-expanded="isDropdownOpen"
    @click="toggleDropDown"
  >
    <slot name="title" :isOpen="isDropdownOpen">
      <a
        class="dropdown-toggle nav-link"
        :class="{ 'no-caret': hideArrow }"
        data-toggle="dropdown"
        :aria-expanded="isOpen"
      >
        <i :class="icon"></i>
        <span class="no-icon">{{ title }}</span>
      </a>
    </slot>
    <ul
      class="dropdown-menu"
      :x-placement="directionAttribute"
      :class="[
        { 'dropdown-menu-right': position === 'right' },
        { show: isDropdownOpen },
      ]"
    >
      <slot></slot>
    </ul>
  </component>
</template>
<script>
import { ref } from "vue";
export default {
  name: "DropDown",
  props: {
    direction: {
      type: String,
      default: "down",
      description: "Drop down menu direction (up|down)",
    },
    title: String,
    icon: String,
    position: {
      type: String,
      description: "Drop down menu arrow position (left|right)",
    },
    hideArrow: {
      type: Boolean,
      description: "Whether to hide drop down arrow",
    },
    tag: {
      type: String,
      default: "li",
      description: "Html tag of the dropdnown",
    },
    isOpen: {
      type: Boolean,
      default: false,
    },
  },
  data(props) {
    return {
      isDropdownOpen: ref(props.isOpen),
    };
  },
  computed: {
    directionAttribute() {
      let baseDirection = "";
      if (this.direction === "up") {
        baseDirection = "top-start";
      } else if (this.direction === "down") {
        baseDirection = "bottom-start";
      }
      return baseDirection;
    },
  },
  methods: {
    toggleDropDown() {
      this.isDropdownOpen = !this.isDropdownOpen;
      this.$emit("change", this.isDropdownOpen);
    },
    closeDropDown() {
      this.isDropdownOpen = false;
      this.$emit("change", this.isDropdownOpen);
    },
  },
};
</script>
<style>
.dropdown {
  list-style-type: none;
}

.dropdown .dropdown-toggle {
  cursor: pointer;
}
</style>
