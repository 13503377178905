// Dashboard pages
const Overview = () => import("src/components/Sections/Overview.vue");
const Widgets = () =>
  import("src/components/Dashboard/Views/Dashboard/Widgets.vue");

// Pages
import User from "src/components/Dashboard/Views/Pages/UserProfile.vue";
import TimeLine from "src/components/Dashboard/Views/Pages/TimeLinePage.vue";
import Register from "src/components/Dashboard/Views/Pages/Register.vue";
import Lock from "src/components/Dashboard/Views/Pages/Lock.vue";

// Components pages
import Buttons from "src/components/Dashboard/Views/Components/Buttons.vue";
import GridSystem from "src/components/Dashboard/Views/Components/GridSystem.vue";
import Panels from "src/components/Dashboard/Views/Components/Panels.vue";
import SweetAlert from "src/components/Dashboard/Views/Components/SweetAlert.vue";
import Notifications from "src/components/Dashboard/Views/Components/Notifications.vue";
import Icons from "src/components/Dashboard/Views/Components/Icons.vue";
import Typography from "src/components/Dashboard/Views/Components/Typography.vue";

// Forms pages
const RegularForms = () =>
  import(
    /* webpackChunkName: "forms" */ "src/components/Dashboard/Views/Forms/RegularForms.vue"
  );
const ExtendedForms = () =>
  import(
    /* webpackChunkName: "forms" */ "src/components/Dashboard/Views/Forms/ExtendedForms.vue"
  );
const ValidationForms = () =>
  import(
    /* webpackChunkName: "forms" */ "src/components/Dashboard/Views/Forms/ValidationForms.vue"
  );
const Wizard = () =>
  import(
    /* webpackChunkName: "forms" */ "src/components/Dashboard/Views/Forms/Wizard.vue"
  );

// TableList pages
const RegularTables = () =>
  import(
    /* webpackChunkName: "tables" */ "src/components/Dashboard/Views/Tables/RegularTables.vue"
  );
const ExtendedTables = () =>
  import(
    /* webpackChunkName: "tables" */ "src/components/Dashboard/Views/Tables/ExtendedTables.vue"
  );
const PaginatedTables = () =>
  import(
    /* webpackChunkName: "tables" */ "src/components/Dashboard/Views/Tables/PaginatedTables.vue"
  );
// Maps pages
const GoogleMaps = () =>
  import(
    /* webpackChunkName: "maps" */ "src/components/Dashboard/Views/Maps/GoogleMaps.vue"
  );
const FullScreenMap = () =>
  import(
    /* webpackChunkName: "maps" */ "src/components/Dashboard/Views/Maps/FullScreenMap.vue"
  );
const VectorMaps = () =>
  import(
    /* webpackChunkName: "maps" */ "src/components/Dashboard/Views/Maps/VectorMapsPage.vue"
  );

// Calendar
//import Calendar from "src/components/Dashboard/Views/Calendar/CalendarRoute.vue";
// Charts
const Charts = () => import("src/components/Dashboard/Views/Charts.vue");

//Jaics Layouts
import DashboardLayout from "../components/Layout/DashboardLayout.vue";

//Jaics Pages
import Login from "src/components/Auth/Login.vue";
import NotFound from "../components/GeneralViews/NotFoundPage.vue";

//// Jaics sections
//Orders
const Orders = () =>
  import(
    /* webpackChunkName: "orders" */ "src/components/Sections/Orders/Orders.vue"
  );

const OrderDetails = () =>
  import(
    /* webpackChunkName: "orders" */ "src/components/Sections/Orders/OrderDetails.vue"
  );

const OrderActivities = () =>
  import(
    /* webpackChunkName: "orders" */ "src/components/Sections/Orders/OrderActivities.vue"
  );

const CreateOrder = () =>
  import(
    /* webpackChunkName: "orders" */ "src/components/Sections/Orders/CreateOrder.vue"
  );

//Contacts
const ContactsList = () =>
  import(
    /* webpackChunkName: "contacts" */ "src/components/Sections/Contacts/ContactsList.vue"
  );

const ContactDetails = () =>
  import(
    /* webpackChunkName: "contacts" */ "src/components/Sections/Contacts/ContactDetails.vue"
  );

const CreateContact = () =>
  import(
    /* webpackChunkName: "contacts" */ "src/components/Sections/Contacts/CreateContact.vue"
  );

//Activities
const Activities = () =>
  import(
    /* webpackChunkName: "activities" */ "src/components/Sections/Activities/Activities.vue"
  );
const ActivitiesList = () =>
  import(
    /* webpackChunkName: "activities" */ "src/components/Sections/Activities/ActivitiesList.vue"
  );

const ActivitiesBalance = () =>
  import(
    /* webpackChunkName: "activities" */ "src/components/Sections/Activities/ActivitiesBalance.vue"
  );

//Persons
const Persons = () =>
  import(
    /* webpackChunkName: "persons" */ "src/components/Sections/Persons/Persons.vue"
  );
const PersonsList = () =>
  import(
    /* webpackChunkName: "persons" */ "src/components/Sections/Persons/PersonsList.vue"
  );
const PersonDetails = () =>
  import(
    /* webpackChunkName: "persons" */ "src/components/Sections/Persons/PersonDetails.vue"
  );
const CreatePerson = () =>
  import(
    /* webpackChunkName: "persons" */ "src/components/Sections/Persons/CreatePerson.vue"
  );
//Reports
const Reports = () =>
  import(
    /* webpackChunkName: "reports" */ "src/components/Sections/Reports/Reports.vue"
  );
//Users
const Users = () =>
  import(
    /* webpackChunkName: "users" */ "src/components/Sections/Admin/Users/Users.vue"
  );
const UsersList = () =>
  import(
    /* webpackChunkName: "users" */ "src/components/Sections/Admin/Users/UsersList.vue"
  );
const UserDetails = () =>
  import(
    /* webpackChunkName: "users" */ "src/components/Sections/Admin/Users/UserDetails.vue"
  );
const CreateUser = () =>
  import(
    /* webpackChunkName: "users" */ "src/components/Sections/Admin/Users/CreateUser.vue"
  );

import Profile from "src/components/Sections/Profile/Profile.vue";

let contactsMenu = {
  path: "/contacts/:type",
  name: "Clients",
  component: DashboardLayout,
  meta: { requiresAuth: true, requiresRoles: ["user", "seller", "admin"] },
  redirect: (to) => {
    return "/contacts/" + to.params.type + "/overview";
  },
  children: [
    {
      path: "overview",
      name: "Contacts list",
      component: ContactsList,
      props: (route) => ({
        type: route.params.type,
      }),
    },
    {
      path: "details/:id",
      name: "Contact Detail",
      props: (route) => ({
        id: Number(route.params.id),
        type: route.params.type,
      }),
      component: ContactDetails,
    },
    {
      path: "create",
      name: "Create Contact",
      component: CreateContact,
      props: true,
    },
  ],
};

let ordersMenu = {
  path: "/orders",
  name: "Orders",
  component: DashboardLayout,
  redirect: "/orders/overview",
  meta: { requiresAuth: true, requiresRoles: ["user", "seller", "admin"] },
  children: [
    {
      path: "overview",
      name: "Orders list",
      component: Orders,
    },
    {
      path: "offers",
      name: "Offers list",
      component: Orders,
      props: () => ({ type: ["offer", "lost"] }),
    },
    {
      path: "details/:id",
      name: "Order Detail",
      props: (route) => ({ id: Number(route.params.id) }),
      component: OrderDetails,
    },
    {
      path: "create",
      name: "Create Order",
      component: CreateOrder,
    },
    {
      path: "activities/:id",
      name: "Order activities list",
      props: (route) => ({ id: Number(route.params.id) }),
      component: OrderActivities,
    },
  ],
};

let componentsMenu = {
  path: "/components",
  component: DashboardLayout,
  redirect: "/components/buttons",
  children: [
    {
      path: "buttons",
      name: "Buttons",
      component: Buttons,
    },
    {
      path: "grid-system",
      name: "Grid System",
      component: GridSystem,
    },
    {
      path: "panels",
      name: "Panels",
      component: Panels,
    },
    {
      path: "sweet-alert",
      name: "Sweet Alert",
      component: SweetAlert,
    },
    {
      path: "notifications",
      name: "Notifications",
      component: Notifications,
    },
    {
      path: "icons",
      name: "Icons",
      component: Icons,
    },
    {
      path: "typography",
      name: "Typography",
      component: Typography,
    },
  ],
};
let formsMenu = {
  path: "/forms",
  component: DashboardLayout,
  redirect: "/forms/regular",
  children: [
    {
      path: "regular",
      name: "Regular Forms",
      component: RegularForms,
    },
    {
      path: "extended",
      name: "Extended Forms",
      component: ExtendedForms,
    },
    {
      path: "validation",
      name: "Validation Forms",
      component: ValidationForms,
    },
    {
      path: "wizard",
      name: "Wizard",
      component: Wizard,
    },
  ],
};

let tablesMenu = {
  path: "/table-list",
  component: DashboardLayout,
  redirect: "/table-list/regular",
  children: [
    {
      path: "regular",
      name: "Regular Tables",
      component: RegularTables,
    },
    {
      path: "extended",
      name: "Extended Tables",
      component: ExtendedTables,
    },
    {
      path: "paginated",
      name: "Paginated Tables",
      component: PaginatedTables,
    },
  ],
};

let mapsMenu = {
  path: "/maps",
  component: DashboardLayout,
  redirect: "/maps/google",
  children: [
    {
      path: "google",
      name: "Google Maps",
      component: GoogleMaps,
    },
    {
      path: "full-screen",
      name: "Full Screen Map",
      component: FullScreenMap,
    },
    {
      path: "vector-map",
      name: "Vector Map",
      component: VectorMaps,
    },
  ],
};

let pagesMenu = {
  path: "/pages",
  component: DashboardLayout,
  redirect: "/pages/user",
  children: [
    {
      path: "user",
      name: "User Page",
      component: User,
    },
    {
      path: "timeline",
      name: "Timeline Page",
      component: TimeLine,
    },
  ],
};

let loginPage = {
  path: "/login",
  name: "Login",
  component: Login,
  meta: { requiresAuth: false },
};

let registerPage = {
  path: "/register",
  name: "Register",
  component: Register,
};

let lockPage = {
  path: "/lock",
  name: "Lock",
  component: Lock,
};

let widgetPage = {
  path: "/widgets",
  name: "Widgets",
  component: Widgets,
};

const uiKitRoutes = [
  componentsMenu,
  formsMenu,
  tablesMenu,
  mapsMenu,
  pagesMenu,
  widgetPage,
  registerPage,
  lockPage,
];

const routes = [
  {
    path: "/",
    name: "root",
    component: DashboardLayout,
    redirect: "/dashboard",
    meta: { requiresAuth: true },
    children: [
      {
        path: "dashboard",
        name: "Overview",
        component: Overview,
      },
    ],
  },
  {
    path: "/",
    name: "sections",
    component: DashboardLayout,
    meta: { requiresAuth: true, requiresRoles: ["user", "seller", "admin"] },
    children: [
      {
        path: "/profile",
        name: "Profile",
        component: Profile,
      },
      /*{
        path: "/orders",
        name: "Orders",
        component: Orders,
        redirect: "/orders/overview",
        children: [
          {
            path: "overview",
            name: "Orders list",
            component: OrdersList,
          },
          {
            path: "details/:id",
            name: "Order Detail",
            props: (route) => ({ id: Number(route.params.id) }),
            component: OrderDetails,
          },
          {
            path: "create",
            name: "Create Order",
            component: CreateOrder,
          },
          {
            path: "activities/:id",
            name: "Order activities list",
            props: (route) => ({ id: Number(route.params.id) }),
            component: OrderActivities,
          },
        ],
      },*/
      {
        path: "/activities",
        name: "Activities",
        component: Activities,
        redirect: "/activities/overview",
        children: [
          {
            path: "overview",
            name: "Activities list",
            component: ActivitiesList,
          },
          {
            path: "balance",
            name: "Balance review",
            component: ActivitiesBalance,
          },
        ],
      },
      {
        path: "/persons",
        name: "Persons",
        component: Persons,
        redirect: "/persons/overview",
        children: [
          {
            path: "overview",
            name: "Persons list",
            component: PersonsList,
          },
          {
            path: "details/:id",
            name: "Person Detail",
            props: (route) => ({ id: Number(route.params.id) }),
            component: PersonDetails,
          },
          {
            path: "create",
            name: "Create Person",
            component: CreatePerson,
          },
        ],
      },
      {
        path: "/reports",
        name: "Reports",
        component: Reports,
      },
      {
        path: "charts",
        name: "Charts",
        component: Charts,
      },
    ],
  },
  {
    path: "/admin",
    name: "Admin",
    redirect: "users/overview",
    component: DashboardLayout,
    meta: {
      requiresAuth: true,
      requiresRoles: ["superadmin", "seller", "admin"],
    },
    children: [
      {
        path: "overview",
        name: "Admin overview",
        component: UsersList,
      },
      {
        path: "users",
        name: "User administration",
        component: Users,
        redirect: "/admin/users/overview",
        children: [
          {
            path: "overview",
            name: "Users list",
            component: UsersList,
          },
          {
            path: "details/:id",
            name: "User Detail",
            props: (route) => ({ id: Number(route.params.id) }),
            component: UserDetails,
          },
          {
            path: "create",
            name: "Create User",
            component: CreateUser,
          },
        ],
      },
    ],
  },
  ordersMenu,
  contactsMenu,
  loginPage,
  ...(() => {
    return process.env.NODE_ENV !== "production" ? uiKitRoutes : [];
  })(),
  { path: "/logout", redirect: "/login" },
  { path: "/:pathMatch(.*)*", component: NotFound },
];

export default routes;
