<template>
  <nav :class="classes" class="navbar navbar-expand-lg">
    <div class="container-fluid">
      <slot></slot>
      <slot name="toggle-button">
        <button
          aria-controls="navigation-index"
          aria-expanded="true"
          aria-label="Toggle navigation"
          class="navbar-toggler"
          data-toggle="collapse"
          type="button"
          @click="toggleMenu"
        >
          <span class="navbar-toggler-bar navbar-kebab"></span
          ><span class="navbar-toggler-bar navbar-kebab"></span
          ><span class="navbar-toggler-bar navbar-kebab"></span>
        </button>
      </slot>
      <collapse-transition>
        <div
          v-show="showNavbar"
          id="navigation"
          class="collapse navbar-collapse justify-content-end show"
          :class="navbarMenuClasses"
        >
          <ul class="navbar-nav">
            <slot name="navbar-menu"></slot>
          </ul>
        </div>
      </collapse-transition>
    </div>
  </nav>
</template>
<script>
import { Collapse as CollapseTransition } from "src/components/Transitions";

export default {
  name: "Navbar",
  components: {
    CollapseTransition,
  },
  props: {
    showNavbar: {
      type: Boolean,
      description: "Whether navbar is visible",
    },
    navbarMenuClasses: {
      type: [String, Object],
      description: "Navbar menu css classes",
    },
    transparent: {
      type: Boolean,
      default: true,
      description: "Whether navbar is transparent",
    },
    position: {
      type: String,
      default: "absolute",
      description: "Navbar position (absolute|fixed|relative)",
    },
    type: {
      type: String,
      default: "white",
      validator(value) {
        return [
          "white",
          "default",
          "primary",
          "danger",
          "success",
          "warning",
          "info",
        ].includes(value);
      },
      description: "Navbar type (primary|info|danger|default|warning|success)",
    },
  },
  emits: ["update:showNavbar"],
  computed: {
    classes() {
      let color = `bg-${this.type}`;
      let navPosition = `navbar-${this.position}`;
      return [
        { "navbar-transparent": !this.showNavbar && this.transparent },
        { [color]: this.showNavbar || !this.transparent },
        navPosition,
      ];
    },
  },
  methods: {
    toggleMenu() {
      this.$emit("update:showNavbar", !this.showNavbar);
    },
  },
};
</script>
<style scoped>
.navbar-relative {
  position: relative;
}
</style>
