<template>
  <navbar v-model="showMenu" navbar-menu-classes="navbar-right">
    <div class="navbar-wrapper">
      <a class="navbar-brand" href="#pablo">JAICS Idealize srl</a>
    </div>
    <template #navbar-menu>
      <!--
      <router-link to="/login" class="nav-item" tag="li">
        <a class="nav-link"> <i class="nc-icon nc-key-25"></i> Login </a>
      </router-link>
      <router-link to="/register" class="nav-item" tag="li">
        <a class="nav-link"> <i class="nc-icon nc-atom"></i> Register </a>
      </router-link>
      <router-link to="/prices" class="nav-item" tag="li">
        <a class="nav-link"> <i class="nc-icon nc-satisfied"></i> Prices </a>
      </router-link>
      <router-link to="/features" class="nav-item" tag="li">
        <a class="nav-link">
          <i class="nc-icon nc-book-bookmark"></i> Features
        </a>
      </router-link>
      -->
    </template>
  </navbar>
</template>

<script>
import { Navbar } from "src/components/UIComponents";
export default {
  name: "MainNavbar",
  components: {
    Navbar,
  },
  data() {
    return {
      showMenu: false,
    };
  },
};
</script>

<style scoped></style>
