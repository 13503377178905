<template>
  <div class="vue-notification">
    <div class="custom-template-icon">
      <i class="icon ion-android-checkmark-circle" />
    </div>
    <div class="notification-content">
      <div class="custom-template-text">
        <span>
          Welcome to <b>Paper Dashboard</b> - a beautiful freebie for every web
          developer.
        </span>
      </div>
    </div>
    <div class="custom-template-close" @click="close">
      <i class="icon ion-android-close" />
    </div>
  </div>
</template>
<script>
export default {
  props: {
    item: { type: Object },
  },
};
</script>
<style></style>
