<template>
  <footer class="footer">
    <div class="container-fluid">
      <nav class="pull-left">
        <ul>
          <li>
            <a href="https://www.idealize.srl"> Idealize srl </a>
          </li>
          <li>
            <a href="http://blog.idealize.srl"> Blog </a>
          </li>
          <li>
            <a href="http://www.idealize.srl/license"> Licenses </a>
          </li>
        </ul>
      </nav>
      <div class="copyright pull-right">
        &copy; Coded with
        <span>
          <i class="fa fa-heart heart"></i> by
          <a
            href="https://www.idealize.srl/?ref=pdf-vuejs"
            rel="noopener"
            target="_blank"
            >Idealize srl</a
          >.</span
        >
      </div>
    </div>
  </footer>
</template>
<script>
export default {};
</script>
<style></style>
