import { createStore } from "vuex";
import axios from "axios";
import contacts from "./modules/contacts";
import orders from "./modules/orders";
import users from "./modules/users";
import activities from "./modules/activities";
import persons from "./modules/persons";
import * as types from "./mutation-types.js";
import createPersistedState from "vuex-persistedstate";
import Cookies from "js-cookie";

const debug = process.env.NODE_ENV !== "production";

const createConfig = (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-type": "application/json",
    },
  };
  return config;
};

export default createStore({
  modules: {
    users,
    contacts,
    orders,
    activities,
    persons,
  },
  strict: debug,
  plugins: [
    createPersistedState({
      paths: ["token", "rememberMe"],
      storage: {
        getItem: (key) => {
          return Cookies.get(key);
        },
        setItem: (key, value) =>
          Cookies.set(key, value, {
            expires: 30,
            secure: !debug,
            sameSite: "strict",
          }),
        removeItem: (key) => Cookies.remove(key),
      },
    }),
  ],
  state: {
    token: null,
    rememberMe: null,
    user: null,
  },
  mutations: {
    [types.SET_TOKEN](state, token) {
      state.token = token;
    },
    [types.SET_REMEMBERME](state, rememberMe) {
      state.rememberMe = rememberMe;
    },
    [types.SET_PROFILE](state, profile) {
      state.user = profile;
    },
  },
  getters: {
    getUser({ user }) {
      return user;
    },
    getToken({ token, rememberMe }) {
      return rememberMe ? token : sessionStorage.token;
    },
  },
  actions: {
    async authenticate({ commit }, credentials) {
      try {
        return await axios
          .post("login", {
            email: credentials.email,
            password: credentials.password,
          })
          .then(({ data }) => {
            console.log("api response OK");
            commit(types.SET_REMEMBERME, credentials.rememberMe); //THIS MUST GO FIRST!
            if (credentials.rememberMe) {
              commit(types.SET_TOKEN, data.token);
            } else {
              sessionStorage.token = data.token;
            }
            return { result: true };
          })
          .catch((error) => {
            console.error("api error" + error);
            return { result: false, message: error.message };
          });
      } catch (e) {
        console.error(e);
        if (e instanceof Error) {
          return { result: false, message: e.message };
        } else {
          return { result: false, message: "Unknown" };
        }
      }
    },
    async fetchUser({ commit, getters }) {
      try {
        return await axios
          .get("user", { ...createConfig(getters["getToken"]) })
          .then(({ data }) => {
            console.log("api response OK");
            commit(types.SET_PROFILE, data.data);

            return { result: true };
          })
          .catch((error) => {
            console.error("api error" + error);
            return { result: false, message: error.message };
          });
      } catch (e) {
        console.error(e);
        if (e instanceof Error) {
          return { result: false, message: e.message };
        } else {
          return { result: false, message: "Unknown" };
        }
      }
    },
    async logout({ commit }) {
      commit(types.SET_TOKEN, null);
      commit(types.SET_REMEMBERME, null);
      sessionStorage.removeItem("token");
      console.log("logout action");
    },
  },
});
