//users.js
export const SET_USERS = "SET_USERS";
export const SET_USER = "SET_USER";

//persons.js
export const SET_PERSONS = "SET_PERSONS";
export const SET_PERSON = "SET_PERSON";
export const SET_PERSON_ORDERS = "SET_PERSON_ORDERS";

//contacts.js
export const SET_CONTACTS = "SET_CONTACTS";
export const SET_CONTACT = "SET_CONTACT";

//orders.js
export const SET_ORDERS = "SET_ORDERS";
export const SET_ORDER = "SET_ORDER";
export const SET_ORDER_PERSONS = "SET_ORDER_PERSONS";
export const SET_ORDER_PERSONS_STATS = "SET_ORDER_PERSONS_STATS";

//activities.js
export const SET_ACTIVITIES = "SET_ACTIVITIES";
