<template>
  <navbar v-model:showNavbar="showNavbar">
    <div class="navbar-wrapper">
      <div class="navbar-minimize">
        <button
          id="minimizeSidebar"
          class="btn btn-icon btn-round"
          @click="minimizeSidebar"
        >
          <i
            class="nc-icon nc-minimal-right text-center visible-on-sidebar-mini"
          ></i>
          <i
            class="nc-icon nc-minimal-left text-center visible-on-sidebar-regular"
          ></i>
        </button>
      </div>
      <div class="navbar-toggle">
        <navbar-toggle-button @click="toggleSidebar"> </navbar-toggle-button>
      </div>
    </div>
    <template #navbar-menu>
      <form v-if="false">
        <div class="input-group no-border">
          <input
            type="text"
            value=""
            class="form-control"
            placeholder="Search..."
          />
          <div class="input-group-append">
            <div class="input-group-text">
              <i class="nc-icon nc-zoom-split"></i>
            </div>
          </div>
        </div>
      </form>
      <ul class="navbar-nav">
        <template v-if="false">
          <li class="nav-item">
            <a class="nav-link btn-magnify" href="#pablo">
              <i class="nc-icon nc-layout-11"></i>
              <p>
                <span class="d-lg-none d-md-block">Stats</span>
              </p>
            </a>
          </li>
          <drop-down
            icon="nc-icon nc-bell-55"
            tag="li"
            position="right"
            direction="none"
            class="nav-item btn-rotate dropdown"
          >
            <template #title="{ isDropdownOpen }">
              <a
                class="nav-link dropdown-toggle"
                data-toggle="dropdown"
                aria-haspopup="true"
                :aria-expanded="isDropdownOpen"
              >
                <i class="nc-icon nc-bell-55"></i>
                <p>
                  <span class="d-lg-none d-md-block">Some Actions</span>
                </p>
              </a>
            </template>
            <a class="dropdown-item" href="#">Action</a>
            <a class="dropdown-item" href="#">Another action</a>
            <a class="dropdown-item" href="#">Something else here</a>
          </drop-down>
        </template>
        <drop-down
          icon="nc-icon nc-settings-gear-65"
          tag="li"
          position="right"
          direction="none"
          class="nav-item btn-rotate dropdown"
        >
          <template #title="{ isDropdownOpen }">
            <a
              class="nav-link dropdown-toggle"
              data-toggle="dropdown"
              aria-haspopup="true"
              :aria-expanded="isDropdownOpen"
            >
              <i class="nc-icon nc-settings-gear-65"></i>
              <p>
                <span class="d-lg-none d-md-block">User</span>
              </p>
            </a>
          </template>
          <a class="dropdown-item" href="#" @click="logout()"
            ><i class="nc-icon nc-key-25" />Sign out</a
          >
        </drop-down>
      </ul>
    </template>
  </navbar>
</template>
<script>
import { Navbar, NavbarToggleButton } from "src/components/UIComponents";

export default {
  components: {
    Navbar,
    NavbarToggleButton,
  },
  inject: {
    sidebar: { default: null },
  },
  data() {
    return {
      isOpen: false,
      activeNotifications: false,
      showNavbar: false,
    };
  },
  methods: {
    async logout() {
      await this.$store.dispatch("logout");
      this.$router.push("/logout");
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    toggleNotificationDropDown() {
      this.activeNotifications = !this.activeNotifications;
    },
    closeDropDown() {
      this.activeNotifications = false;
    },
    toggleSidebar() {
      this.sidebar.displaySidebar(!this.sidebar.showSidebar);
    },
    hideSidebar() {
      this.sidebar.displaySidebar(false);
    },
    minimizeSidebar() {
      this.sidebar.toggleMinimize();
    },
    toggleNavbar() {
      this.showNavbar = !this.showNavbar;
    },
  },
};
</script>
<style></style>
