<template>
  <component
    :is="componentType"
    :tag="tag"
    v-bind="$attrs"
    enter-active-class="zoomInUp"
    move-class="zoom-move"
    leave-active-class="zoomOutUp"
    v-on="hooks"
  >
    <slot></slot>
  </component>
</template>
<script>
import { baseTransition } from "../mixins/index.js";
import { Transition, TransitionGroup } from "vue";

export default {
  name: "ZoomUpTransition",
  components: {
    Transition,
    TransitionGroup,
  },
  mixins: [baseTransition],
  props: {
    styles: {
      type: Object,
      default: () => {
        return {
          animationFillMode: "both",
          animationTimingFunction: "ease-out",
        };
      },
    },
  },
};
</script>
<style lang="scss">
@import "move";

@keyframes zoomInUp {
  from {
    opacity: 0;
    transform: scaleY(0);
    transform-origin: top center;
  }

  50% {
    opacity: 1;
  }

  to {
    transform: scaleY(1);
  }
}

.zoomInUp {
  animation-name: zoomInUp;
}

@keyframes zoomOutUp {
  from {
    opacity: 1;
  }

  50% {
    transform: scaleY(0);
  }

  to {
    opacity: 0;
  }
}

.zoomOutUp {
  animation-name: zoomOutUp;
}
</style>
