<template>
  <component
    :is="componentType"
    :tag="tag"
    v-bind="$attrs"
    enter-active-class="fadeIn"
    move-class="fade-move"
    leave-active-class="fadeOut"
    v-on="hooks"
  >
    <slot></slot>
  </component>
</template>
<script>
import { baseTransition } from "../mixins/index.js";
import { Transition, TransitionGroup } from "vue";

export default {
  name: "FadeTransition",
  components: {
    Transition,
    TransitionGroup,
  },
  mixins: [baseTransition],
};
</script>
<style>
@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.fadeIn {
  animation-name: fadeIn;
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.fadeOut {
  animation-name: fadeOut;
}

.fade-move {
  transition: transform 0.3s ease-out;
}
</style>
