import PerfectScrollbar from "vue3-perfect-scrollbar";
import { createApp } from "vue";
import App from "./App.vue";
import "./pollyfills";
import * as VueRouter from "vue-router";
//import router from "./vue-router";
//import VueNotify from "vue-notifyjs";
import Notifications from "@kyvg/vue3-notification";
import ElementPlus from "element-plus";
import en from "element-plus/es/locale/lang/en";
import axios from "axios";
import VueAxios from "vue-axios";

// Plugins
//import PerfectScrollbar from "vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.esm";
import GlobalComponents from "./globalComponents";
import GlobalDirectives from "./globalDirectives";
import SideBar from "./components/UIComponents/SidebarPlugin";
import initProgress from "./progressbar";
//import { ElLoading } from "element-plus";

//Mixins
import emptySlot from "./components/UIComponents/mixins/emptySlot";

// router setup
import routes from "./routes/routes";

// store setup
import store from "./store";

// library imports

import "./assets/sass/paper-dashboard.scss";
import "./assets/sass/demo.scss";

import { userSidebar, adminSidebar, uiKitSidebar } from "./sidebarLinks";
// plugin setup

// configure router
/*const router = new VueRouter({
  routes, // short for routes: routes
  linkActiveClass: "active",
  scrollBehavior: (to) => {
    if (to.hash) {
      return { selector: to.hash };
    } else {
      return { x: 0, y: 0 };
    }
  },
});*/

// Routing setup
const router = VueRouter.createRouter({
  history: VueRouter.createWebHashHistory(),
  routes, // short for `routes: routes`,
  linkActiveClass: "active",
  scrollBehavior: (to) => {
    if (to.hash) {
      return { selector: to.hash };
    } else {
      return { left: 0, top: 0 };
    }
  },
});

initProgress(router);

//Block unauthorized routes
router.beforeEach(async (to) => {
  const loggedIn = store.getters["getToken"];
  if (to.matched.some((record) => record.meta.requiresAuth) && !loggedIn) {
    return "/login";
  }
});

router.beforeEach(async (to) => {
  const loggedIn = store.getters["getToken"];
  if (to.matched.some((record) => record.meta.requiresAuth) && loggedIn) {
    const result = await store.dispatch("fetchUser");
    console.log(`user fetch in routing: ${result.result}`);
  }
});

router.beforeEach((to) => {
  const metarole = to.matched.find((record) => record.meta.requiresRoles);
  if (metarole) {
    if (store.state.user) {
      const statx = store.state as any;
      if (
        !statx.user.roles.some((role: string) =>
          (metarole.meta.requiresRoles as Array<string>).some(
            (required: string) => required === role
          )
        )
      ) {
        return "/";
      }
    } else {
      return "/login";
    }
  }
});

/*router.afterEach(async (to, from, next) => {
  const loggedIn = store.getters["getToken"];
  if (to.matched.some((record) => record.meta.requiresAuth) && loggedIn) {
  }
});*/

// App initialization and creation

const app = createApp(App)
  .use(router)
  .use(ElementPlus, {
    locale: en,
  })
  .use(store)
  .use(Notifications)
  .use(GlobalComponents)
  .use(GlobalDirectives)
  .use(PerfectScrollbar)
  .use(SideBar, { sidebarLinks: [userSidebar, adminSidebar, uiKitSidebar] })
  .mixin(emptySlot);

//Axios setup
const debug = process.env.NODE_ENV !== "production";
axios.defaults.baseURL = process.env.VUE_APP_BASE_URL;
axios.interceptors.request.use(
  (config) => {
    /** In dev, intercepts request and logs it into console for dev */
    if (debug) {
      console.info("✉️ ", config);
    }
    return config;
  },
  (error) => {
    if (debug) {
      console.error("✉️ ", error);
    }
    return Promise.reject(error);
  }
);
axios.interceptors.response.use(
  (response) => response, // simply return the response
  (error) => {
    if (error.response.status === 401) {
      // if we catch a 401 error
      store.dispatch("logout"); // force a log out
    }
    return Promise.reject(error); // reject the Promise, with the error as the reason
  }
);
app.use(VueAxios, axios).provide("axios", app.config.globalProperties.axios);

//Finally mount app
app.mount("#app");
